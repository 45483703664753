<template>
    <div>
        <b-form-row>
            <!-- name -->
            <b-form-group
                id="group-locale.name"
                :label="$t('form.name')"
                class="col-sm-12 col-md-6"
                description=""
                label-class="mb-0"
                label-for="locale.name"
            >
                <b-form-input
                    id="locale.name"
                    v-model="pictureLocale.name"
                ></b-form-input>
            </b-form-group>
            <!-- alt -->
            <b-form-group
                id="group-locale.alt"
                :label="$t('picture.alt')"
                class="col-sm-12 col-md-6"
                description=""
                label-class="mb-0"
                label-for="locale.alt"
            >
                <b-input-group>
                    <b-form-input
                        id="locale.alt"
                        v-model="pictureLocale.alt"
                    ></b-form-input>
                    <b-input-group-append>
                        <b-button
                            v-b-toggle.collapse-help-alt variant="info">?
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
                    <!-- collapse:message alt -->
                    <b-collapse id="collapse-help-alt">
                        <b-card class="bg-info __help_message">
                            <div v-html="$t('picture.help_message.alt')"></div>
                        </b-card>
                    </b-collapse>
            </b-form-group>
            <!-- longdesc -->
            <b-form-group
                id="group-locale.longdesc"
                :label="$t('picture.longdesc')"
                class="col-sm-12"
                description=""
                label-class="mb-0"
                label-for="locale.longdesc"
            >
                <b-form-input
                    id="locale.longdesc"
                    v-model="pictureLocale.longdesc"
                ></b-form-input>
            </b-form-group>
            <!-- keywords -->
            <b-form-group
                id="group-locale.keyword"
                :label="$t('picture.keyword.label')"
                class="col-sm-12"
                description=""
                label-class="mb-0"
                label-for="locale.keyword"
            >
                <b-input-group>
                    <b-form-tags
                        id="locale.keyword"
                        v-model="pictureLocale.keywords"
                        :duplicateTagText="$t('picture.keyword.duplicate')"
                        :invalidTagText=null
                        :placeholder="null"
                        :tag-validator="validator"
                        addButtonText="Dodaj"
                        remove-on-delete
                        separator=" ,;"
                    ></b-form-tags>
                    <!-- button: help keyword -->
                    <b-input-group-append>
                        <b-button
                            v-b-toggle.collapse-help-keyword variant="info">?
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
                <!-- collapse:help keyword -->
                <b-collapse id="collapse-help-keyword">
                    <b-card class="bg-info __help_message">
                        {{$t('picture.keyword.help')}}
                    </b-card>
                </b-collapse>
            </b-form-group>
        </b-form-row>
        <!-- notes -->
        <b-form-group
            id="group-record.locale.notes"
            :label="$t('form.notes')"
            class="col-sm-12 p-0"
            description=""

            label-class="mb-0"
            label-for="record.locale.notes"
        >
            <b-form-textarea
                v-model="pictureLocale.notes"
            ></b-form-textarea>
        </b-form-group>
    </div>
</template>

<script>
export default {
    name: "FormPictureLocaleDeprecated",
    components: {},
    props: {
        locale: '',
        pictureLocale: {
            type: Object,
            default: {}
        },
    },
    data() {
        return {}
    },
    mounted() {
    },
    methods: {
        validator(tag) {
            return tag.length > 2
        },
    },
    computed: {},
    filters: {},
    watch: {
        'pictureLocale.keywords': {
            handler: function () {
                let lastIndex = this.pictureLocale.keywords.length - 1;
                if (lastIndex < 0) {
                    return;
                }
                this.pictureLocale.keywords[lastIndex] = this.pictureLocale.keywords[lastIndex].toLowerCase();
            },
            deep: true
        },
    }
}
</script>

<style scoped>

</style>
