<template>
    <div>
        <b-overlay :opacity="overlay.opacity" :show="overlay.visible" rounded="sm">
            <b-form ref="form" @submit.prevent="submit">
                <!-- left tabs -->
                <b-card no-body>
                    <b-tabs v-model="tabIndex" card pills vertical>
                        <!-- tab image -->
                        <b-tab :title="$t('picture.tab.image')" active>
                            <b-img :alt="form.picture.image.src"
                                   :src="getImageSrc"
                                   draggable="false"
                                   fluid-grow
                            ></b-img>
                            <!-- about image -->
                            <b-container class="p-1 border text-right">
                                <b-badge :variant="getVariant" class="mr-1">{{getImageDimension}}</b-badge>
                                <b-badge :variant="getVariant" class="mr-1">
                                    {{form.picture.image.size_bytes | bytesToSizeLocale}}
                                </b-badge>
                                <b-badge :variant="getVariant">{{form.picture.image.type}}</b-badge>
                            </b-container>
                        </b-tab>
                        <!-- tab basic -->
                        <b-tab :title="$t('picture.tab.basic')" :title-link-class="tabValidBasic">
                            <!-- meta -->
                            <b-form-group
                                :label="$tc('form.meta.data', 0)"
                                class="mb-0 group-title p-0"
                                label-align-sm="left"
                                label-class="font-weight-bold"
                                label-cols-sm="12"
                                label-size=""
                            >
                                <b-form-row>
                                    <!-- name -->
                                    <b-form-group
                                        id="group-meta.name"
                                        :label="$t('form.name')"
                                        :state="valid_metaName"
                                        class="col-sm-12 col-md-6"
                                        description=""
                                        label-class="mb-0"
                                        label-for="meta.name"
                                    >
                                        <b-form-input
                                            id="meta.name"
                                            v-model="form.picture.meta.name"
                                            :placeholder="$t('form.required_field')"
                                            :state="valid_metaName"
                                            required
                                        ></b-form-input>
                                    </b-form-group>
                                    <!-- alt -->
                                    <b-form-group
                                        id="group-meta.alt"
                                        :label="$t('picture.alt')"
                                        :state="valid_metaAlt"
                                        class="col-sm-12 col-md-6"
                                        description=""
                                        label-class="mb-0"
                                        label-for="meta.alt"
                                    >
                                        <b-input-group>
                                            <b-form-input
                                                id="meta.alt"
                                                v-model="form.picture.meta.alt"
                                                :state="valid_metaAlt"
                                                required
                                            ></b-form-input>
                                            <b-input-group-append>
                                                <b-button
                                                    v-b-toggle.collapse-help-alt variant="info">?
                                                </b-button>
                                            </b-input-group-append>
                                        <!-- collapse:message alt -->
                                        <b-collapse id="collapse-help-alt">
                                            <b-card class="bg-info __help_message">
                                                <div v-html="$t('picture.help_message.alt')"></div>
                                            </b-card>
                                        </b-collapse>
                                        </b-input-group>
                                    </b-form-group>
                                    <!-- author -->
                                    <b-form-group
                                        id="group-author.alt"
                                        :label="$t('picture.author')"
                                        class="col-sm-12 col-md-6"
                                        description=""
                                        label-class="mb-0"
                                        label-for="meta.alt"
                                    >
                                        <b-input-group>
                                            <b-form-input
                                                id="meta.alt"
                                                v-model="form.picture.meta.author"
                                            ></b-form-input>
                                            <b-input-group-append>
                                                <b-button
                                                    v-b-toggle.collapse-help-author variant="info">?
                                                </b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                            <!-- collapse:message alt -->
                                            <b-collapse id="collapse-help-author">
                                                <b-card class="bg-info __help_message">
                                                    <div v-html="$t('picture.help_message.author')"></div>
                                                </b-card>
                                            </b-collapse>
                                    </b-form-group>
                                </b-form-row>
                            </b-form-group>
                        </b-tab>
                        <!-- locale -->
                        <b-tab :title="$t('picture.tab.locale')">
                            <b-form-group
                                :label="$t('form.locale.locale')"
                                class="mb-0 group-title p-0"
                                label-align-sm="left"
                                label-class="font-weight-bold p-0 pl-1"
                                label-cols-sm="12"
                                label-size=""
                            >
                                <b-card no-body>
                                    <b-tabs card pills>
                                        <b-tab v-for="locale in data.locale"
                                               :active="locale.locale === $i18n.locale"
                                               :title="locale.locale" style="background: white">
                                            <FormPictureLocaleDeprecated
                                                :locale="locale.locale"
                                                :picture-locale.sync="setPictureLocale[locale.locale]"
                                            ></FormPictureLocaleDeprecated>
                                        </b-tab>
                                    </b-tabs>
                                </b-card>
                            </b-form-group>
                        </b-tab>
                    </b-tabs>
                </b-card>
            </b-form>
        </b-overlay>
    </div>
</template>

<script>
import FormPictureLocaleDeprecated from "@/components/general/elements/pictures/pictures_manager_deprecated/FormPictureLocaleDeprecated";

export default {
    name: "FormPictureDeprecated",
    components: {FormPictureLocaleDeprecated},
    props: {
        form: {
            valid: false,
            picture: {},
            record: {}
        },
        data: {},
        modalBus: Object,
    },
    data() {
        return {
            overlay: {
                visible: false,
                opacity: 0.7,
            },
            valid: null,
            tabIndex: 0,
            picture: {},
        }
    },
    mounted: function () {
        this.modalBus.$on('submit', this.submit);
    },
    destroyed() {
        this.showOverlay(false);
    },
    methods: {
        showOverlay(show) {
            this.overlay.visible = show;
        },
        // <-- form -->
        submit() {
            // this.showOverlay(true);
            if (this.$refs.form === undefined) {
                return;
            }
            this.valid = this.$refs.form.checkValidity();
            this.$set(this.form, 'valid', this.valid);
        }
    },
    computed: {
        getVariant() {
            if (this.form.picture.id > 0) {
                return 'info';
            }
            return 'warning';
        },
        // publishText: {
        //     get() {
        //         return !this.record.publish || this.record.publish === 'false' || this.record.publish === undefined
        //             ? this.$t('unpublish')
        //             : this.$t('publish');
        //     },
        // },
        // <-- locale -->
        setPictureLocale() {
            let pictureLocale = this.form.picture.locale == null ? {} : this.form.picture.locale;
            this.data.locale.forEach((value, index) => {
                // <-- add missing locale record -->
                if (this.form.picture.locale === null || Object.keys(this.form.picture.locale).indexOf(value.locale) === -1) {
                    this.$set(pictureLocale, value.locale, {
                        name: '',
                        alt: '',
                        longdesc: '',
                        keywords: [],
                        notes: '',
                    });
                }
            });
            this.$set(this.form.picture, 'locale', pictureLocale);
            return this.form.picture.locale;
        },
        // <-- picture -->
        getImageSrc() {
            let src = this.form.picture.image.src;
            if (this.form.picture.id === 0) {
                return src;
            }
            return process.env.VUE_APP_API_IMAGES_PND + '/' + src;
        },
        getSize() {
            let width = this.width;
            let height = this.height;
            // <--  -->
            return {'width': width + 'px', 'height': height + 'px'};
        },
        getImageSize() {
            let width = this.width - this.image_padding_x;
            let height = this.height - this.image_padding_y;
            height -= this.header_visible ? this.header_height : 0;
            height -= this.footer_visible ? this.footer_height + 5 : 0;
            // <--  -->
            return {'max-width': width + 'px', 'max-height': height + 'px'};
        },
        // <-- image -->
        getImageDimension() {
            if (this.form.picture.image.width !== null && this.form.picture.image.height !== null) {
                return this.form.picture.image.width + ' x ' + this.form.picture.image.height + '  px';
            }
            let img = new Image();
            img.src = this.form.picture.image.src;
            return img.naturalWidth + ' x ' + img.naturalHeight + '  px';
        },
        // <-- valid meta -->
        valid_metaName() {
            if (this.valid === null) {
                return null;
            }
            return this.form.picture.meta.name.length > 0 ? null : false;
        },
        valid_metaAlt() {
            if (this.valid === null) {
                return null;
            }
            return this.form.picture.meta.alt.length > 0 ? null : false;
        },
        // <-- tabs valid -->
        tabValidBasic() {
            if (this.valid === null) {
                return null;
            }
            let tab = 1;
            if (tab === this.tabIndex) {
                return '';
            }
            let valid = this.valid_metaName == null && this.valid_metaAlt == null;
            return valid ? null : ['text-danger'];
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
