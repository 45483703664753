<template>
    <b-overlay :opacity="overlay.opacity" :show="overlay.visible" rounded="sm">
        <!-- form -->
        <form enctype="multipart/form-data" novalidate>
            <!-- header, image upload -->
            <b-form-group
                :label="$t('picture.picture_manager')"
                label-class="pl-3 font-weight-bold"
                label-size=""
            >

                <b-button @click="$refs.fileInput.$el.querySelector('input[type=file]').click()">
                    {{$tc('picture.add_picture', multiple ? 2 : 1)}}
                </b-button>


                <!-- form-file -->
                <!--                <b-form-file-->
                <!--                    ref="fileInput"-->
                <!--                    v-model="files"-->
                <!--                    :browse-text="$tc('picture.add_picture', multiple ? 2 : 1)"-->
                <!--                    :drop-placeholder="$t('picture.drop_files_here')"-->
                <!--                    :file-name-formatter="formatNames"-->
                <!--                    :placeholder="$t('picture.no_picture_chosen')"-->
                <!--                    accept="image/jpeg, image/png, image/gif"-->
                <!--                    no-drop-placeholder="Not allowed"-->
                <!--                    style="overflow: hidden;"-->
                <!--                    @change="addFiles"-->
                <!--                >-->
                <b-form-file
                    ref="fileInput"
                    v-model="files"
                    :multiple="multiple"
                    style="display:none;"
                    @change="addFiles"
                >
                </b-form-file>
                <!-- warning messages -->
                <b-alert :show="error.count || error.size" variant="danger">
                    <b-row>
                        <b-col class="font-weight-bold" cols="auto">
                            {{$t('picture.warning.loading')}}:
                        </b-col>
                        <!-- warning: too many images -->
                        <b-col v-if="error.count" :class="error.size ? '' : 'mr-auto'" cols="auto">
                            {{$t('picture.warning.too_many')}} (<span
                            class="font-weight-bold">{{this.filesCount}}</span>).
                            {{$t('picture.warning.reduce')}}
                            <span class="font-weight-bold">{{this.filesCountMax}}</span>.
                        </b-col>
                        <!-- warning: too large -->
                        <b-col v-if="error.size" class="mr-auto" cols="auto">
                            {{$t('picture.warning.too_large')}} (<span
                            class="font-weight-bold">{{this.filesSize | bytesToSizeLocale}}</span>).
                            {{$t('picture.warning.reduce')}} <span
                            class="font-weight-bold">{{this.filesSizeMax | bytesToSizeLocale}}</span>.
                        </b-col>

                        <!-- button close -->
                        <b-button-close @click="clearErrors()"></b-button-close>
                    </b-row>
                </b-alert>
                <!-- images loading progress -->
                <b-progress
                    v-show="progress.visible"
                    :max="progress.max"
                    :precision="2"
                    :value="progress.value"
                    class="mt-2"
                    show-progress
                    variant="success"
                />
            </b-form-group>
            <!-- no selected pictures -->
            <div v-if="pictures.length === 0"
                 class="d-flex justify-content-center align-items-center text-secondary border font-weight-bold"
                 style="height: 100px;"
            >
                {{$t('picture.no_selected_pictures')}}
            </div>

            <!-- pictures grid -->
            <grid v-else
                  :cell-height="picture.height + picture.grid"
                  :cellWidth="picture.width + picture.grid"
                  :draggable="true"
                  :items="pictures"
                  :sortable="true"
                  @sort="eventGridChange">
                >
                <!-- @change="gridChange"-->
                <!-- @click="gridClick"-->
                <!-- @remove="gridRemove"-->
                <template slot="cell" scope="gridProps">
                    <PictureDeprecated
                        :pathMain="pathMain"
                        :picture="gridProps.item"
                        @eventPicturePublish="eventPicturePublish"
                        @eventPictureRemove="eventPictureRemove"
                        @eventPictureUpdate="eventPictureUpdate"
                        @showOverlay="showOverlay"
                    />
                    <!--                            @pictureTrash="pictureTrash"-->
                </template>
            </grid>

        </form>
    </b-overlay>
</template>

<script>
import PictureDeprecated from "@/components/general/elements/pictures/pictures_manager_deprecated/PictureDeprecated";

export default {
    name: "PictureManagerDeprecated",
    components: {PictureDeprecated},
    props: {
        imagesFolder: {
            type: String,
            default: 'unsorted'
        },
        multiple: {
            type: Boolean,
            default: true
        },
        picture: {
            type: Object,
            default: function () {
                return {
                    width: 220,
                    height: 250,
                    grid: 5,
                }
            }
        },
        filesCountMax: {
            type: Number,
            default: 50
        },
        filesSizeMax: {
            type: Number,
            default: 125829120,  // <-- 120 MB -->
        },
        record: {
            type: Object,
            default: () => {
            }
        },
        pictureManagerBus: Object,
    },
    data() {
        return {
            isOpened: false,
            overlay: {
                visible: false,
                opacity: 0.7,
            },
            filesCount: 0,
            filesSize: 0,
            error: {
                count: false,
                size: false
            },
            // <--  -->
            pathMain: process.env.VUE_APP_API_IMAGES_PND + '/' + `api/v1/register/pictures`,
            path1: process.env.VUE_APP_API_IMAGES_PND + '/' + `api/v1/register/picture`,
            files: [],
            pictures: [],
            pictures_order: [],
            progress: {
                visible: false,
                value: 0,
                max: 0,
            }
        }
    },
    mounted() {
        this.pictureManagerBus.$on('recordLoad', (record) => {
            this.setPictures(record);
        });
    },
    methods: {
        showOverlay(show) {
            this.overlay.visible = show;
        },
        setPictures(record) {
            this.pictures_order = [];
            this.pictures = [];
            let picture = {};
            record.pictures_order.forEach((order, index) => {
                this.pictures_order.push(order);
                record.pictures.find((n) => {
                    if (order.key === n.key) {
                        picture = {
                            index: index + 1,
                            publish: order.publish,
                            id: n.id,
                            key: n.key,
                            image: n.image,
                            meta: n.meta,
                            locale: n.locale
                        };
                        this.pictures.push(picture);
                        picture = {};
                    }
                });
            });
        },
        parentUpdate() {
            let data = {
                pictures_order: this.pictures_order,
                pictures: this.pictures
            };
            this.$emit('eventUpdate', data);
        },
        // <-- check -->
        clearSelectedFiles() {
            setTimeout(() => {
                this.files = [];
            }, 1000);
        },
        clearErrors() {
            let error = {
                count: false,
                size: false
            };
            this.$set(this, 'error', error);
        },
        checkFilesError(files) {
            this.clearErrors();
            let filesSizeAll = 0;

            files.forEach(file => {
                filesSizeAll += file.size;
            });
            this.filesCount = files.length;
            this.filesSize = filesSizeAll;

            let error = {
                count: this.filesCount > this.filesCountMax,
                size: this.filesSize > this.filesSizeMax
            }
            this.$set(this, 'error', error);
            // <--  -->
            let errors = error.count || error.size;
            if (errors) {
                this.showOverlay(false);
            }
            return errors;
        },
        // <-- files -->
        addFiles(event) {
            this.showOverlay(true);
            let files = this.__convertObjetToArray(event.target.files);
            if (this.checkFilesError(files)) {
                this.clearSelectedFiles()
                return;
            }
            // <-- create and add pictures -->
            let lastIndex = this.pictures.length;
            let picture = {};
            let src, name;
            let loadImage = 0;

            this.progress.max = this.filesCount;
            this.progress.value = 0;
            this.progress.visible = true;

            files.forEach((file, index) => {
                src = URL.createObjectURL(file);
                name = file.name;
                name = name.substr(0, name.lastIndexOf('.'));
                picture = {
                    index: lastIndex + 1 + index,
                    publish: true,
                    id: 0,
                    file: file,
                    key: this.CryptoJS.MD5(index + '_' + Date.now().toString()).toString(),
                    image: {
                        src_p: src,
                        src: src,
                        type: file.type.substr(file.type.lastIndexOf('/') + 1, file.type.length),
                        width: null,
                        height: null,
                        size_bytes: file.size
                    },
                    meta: {
                        alt: name,
                        name: name,
                        author: null
                    },
                    locale: null
                };
                // <-- Wait for multiple images to load -->
                let img = new Image();
                img.src = src;
                img.onload = () => {
                    loadImage++;
                    this.progress.value = loadImage;
                    if (loadImage === this.filesCount) {
                        this.progress.visible = false;
                        this.showOverlay(false);
                    }
                }
                // <-- push images to pictures -->
                this.pictures_order.push(
                    {
                        key: picture.key,
                        publish: true
                    }
                );
                this.pictures.push(picture);
            });
            this.clearSelectedFiles();
            this.parentUpdate();

            // this.showOverlay(false);
        },
        formatNames(files) {
            // if (files.length > 4) {
            //     return this.$tc('picture.selected_images', files.length, {count: files.length});
            // }
            // let names = '';
            // files.forEach((value, index) => {
            //     names += index > 0 ? ', ' + value.name : value.name;
            // });
            // return names;
        },
        // <-- events -->
        eventGridChange(event) {
            this.pictures_order = [];
            event.items.forEach(value => {
                this.pictures_order.push(
                    {
                        key: value.item.key,
                        publish: value.item.publish
                    }
                );
            });
            this.parentUpdate();
        },
        eventPictureUpdate() {
            this.parentUpdate();
        },
        eventPicturePublish(data) {
            this.pictures_order.find(n => {
                if (n.key === data.key) {
                    return n;
                }
            }).publish = data.publish;
            // <--  -->
            this.parentUpdate();
        },
        eventPictureRemove(key) {
            // <-- remove key form pictures_order -->
            let index = -1;
            this.pictures_order.find((n, indexTemp) => {
                if (n.key === key) {
                    index = indexTemp;
                }
            });
            this.pictures_order.splice(index, 1);
            // <-- remove picture form pictures -->
            index = -1;
            this.pictures.find((n, indexTemp) => {
                if (n.key === key) {
                    index = indexTemp;
                }
            });
            this.pictures.splice(index, 1);
            // <--  -->
            this.parentUpdate();
        },

// TODO brisi deprecated **********************************


        // setPicturesAllData(rawData) {
        //     // let pictureSortIds = [];
        //     // let picture = {};
        //     // rawData.forEach((data, index) => {
        //     //     picture = {
        //     //         index: index + 1,
        //     //         publish: true,
        //     //         id: data.id,
        //     //         key: data.key,
        //     //         image: data.image,
        //     //         meta: data.meta,
        //     //         locale: data.locale
        //     //     };
        //     //     this.pictures.push(picture);
        //     //     pictureSortIds.push(data.id);
        //     //     picture = {};
        //     // });
        //     // this.$set(this, 'pictures_order', pictureSortIds);
        //     // this.$emit('update', this.pictures);
        //     // this.$emit('change', this.pictures_order);
        // },

        setPicturesData(rawData) {
            let pictures = [];
            let picture = {};
            // this.record.pictures_order.forEach((order, index) => {
            //     // this.picture = [];
            //     rawData.find((n) => {
            //         if (order.key === n.key) {
            //             picture = {
            //                 index: index + 1,
            //                 publish: record.publish,
            //                 id: data.id,
            //                 key: data.key,
            //                 image: data.image,
            //                 meta: data.meta,
            //                 locale: data.locale
            //             };
            //             this.record.pictures.push('picture');
            //             picture = {};
            //         }
            //     });
            //     console.log("pictures", pictures); // TODO brisi
            //     // this.$set(this.record, 'pictures', pictures);
            //
            //     // this.$emit('update', pictures);
            //
            // });
            // this.$set(this, 'pictures_order', pictures_order);
            // this.$emit('update', this.pictures);
            // this.$emit('change', this.pictures_order);
        },
        updatePicturePublish(records) {
            // this.pictures.forEach(picture => {
            //     records.find((n) => {
            //         if (picture.id === n.pictureId) {
            //             picture.publish = n.publish;
            //         }
            //     });
            // });
        },
        // <-- picture -->
        pictureTrash(id) {
            // this.axios.delete(this.pathMain + `/${id}`)
            //     .then((response) => {
            //         let hash = this.CryptoJS.MD5(id.toString()).toString();
            //         let hashResponse = response.data;
            //         if (hash === hashResponse) {
            //             // <-- remove picture form pictures -->
            //             let index = -1;
            //             this.pictures.find((n, ind) => {
            //                 if (n.id === id) {
            //                     index = ind;
            //                 }
            //             });
            //             this.pictures.splice(index, 1);
            //         }
            //     })
            //     .catch((error) => {
            //         if (error.response) {
            //         }
            //     });
        },

        // <-- base -->
        // loadPictures() {
        //     console.log("loadPictures"); // TODO brisi
        //     return;
        //
        //     if (this.record.pictures_order == null || this.record.pictures_order.length === 0) {
        //         return;
        //     }
        //     //             this.showOverlay(true);
        //     let keys = [];
        //     this.record.pictures_order.forEach(record => {
        //         keys.push(record.key);
        //     });
        //     this.axios.get(this.path1 + `/load_selected_pictures`,
        //         {
        //             params: {
        //                 'keys': keys
        //             }
        //         })
        //         .then((response) => {
        //             this.setPicturesData(response.data);
        //             // this.showOverlay(false);
        //         })
        //         .catch((error) => {
        //             // on error executed
        //         })
        //         .finally(function () {
        //             // always executed
        //         });
        // }


        /*        loadPictures() {
                    this.pictures = [];
                    if (this.record === null || this.record.length === 0) {
                        this.baseSelectAll();
                        return;
                    }
                    this.baseSelect();
                },
                baseSelectAll() {
                    console.log("baseSelectAll"); // TODO brisi
                    return;
                    // this.showOverlay(true);
                    // this.pictures = [];
                    // this.axios.get(this.pathMain)
                    //     .then((response) => {
                    //         this.setPicturesAllData(response.data);
                    //         this.showOverlay(false);
                    //     })
                    //     .catch((error) => {
                    //         // on error executed
                    //     })
                    //     .finally(function () {
                    //         // always executed
                    //     });
                },
                baseSelect() {
                    console.log("baseSelect"); // TODO brisi
                    return;
        //             this.showOverlay(true);
        //             let pictures_order = [];
        //             this.records.forEach(record => {
        //                 pictures_order.push(record.pictureId);
        //             });
        // console.log("TU MORA BITI get namesto post"); // TODO brisi
        //             this.axios.post(this.path1 + `/load_selected_pictures`,
        //                 {
        //                     'pictures_order': pictures_order
        //                 })
        //                 .then((response) => {
        //                     this.setPicturesData(response.data);
        //                     this.showOverlay(false);
        //                 })
        //                 .catch((error) => {
        //                     // on error executed
        //                 })
        //                 .finally(function () {
        //                     // always executed
        //                 });
                },*/


        // <-- deprecated --> // TODO NE brisi
        /*        addFiles(event) {
                    // this.showOverlay(true);
                    let files = event.target.files;
                    if (this.checkFilesError(files)) {
                        this.clearSelectedFiles()
                        return;
                    }
                    // this.setPicturesNew(files);

                    // <-- api for saving pictures -->
                    const config = {
                        headers: {
                            'content-type': 'multipart/form-data',
                        }
                    }
                    // <-- form data -->
                    let formData = new FormData();
                    formData.append('imagesFolder', this.imagesFolder);
                    files.forEach((file, index) => {
                        formData.append('files[' + index + ']', file);
                        formData.append('names[' + index + ']', file.name);
                        formData.append('keys[' + index + ']', this.CryptoJS.MD5(index + '_' + Date.now().toString()).toString());
                    });
                    // <-- send upload request -->
                    axios.post(this.path1 + `/upload_images`,
                        formData, config)
                        .then((response) => {
                            this.clearSelectedFiles();
                            this.setPicturesData(response.data);
                            this.showOverlay(false);
                        })
                        .catch((error) => {
                        });
                },*/
    },
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
