<template>
    <div>
        <!-- images -->
        <b-card :class="picture.id > 0 ? 'border' : 'border-new border-warning'"
                :style="getSize"
                no-body
        >
            <!-- header: index, title, publish checkbox -->
            <template v-if="header_visible" v-slot:header>
                <b-container>
                    <b-row class="border">
                        <!-- picture index, name -->
                        <b-col class="p-0 pl-1">
                            <b-badge class="pr-1" variant="primary">{{picture.index}}</b-badge>
                            <span class="align-middle">
                                {{getName}}
                            </span>
                        </b-col>
                        <!-- picture publish-->
                        <b-col class="left p-0 pl-1 pt-0" cols="2">
                            <b-form-checkbox
                                :id="'publish_' + picture.key"
                                v-model="picture.publish"
                                :name="'publish_' + picture.key"
                                class="ml-0"
                                switch
                                @change="picturePublish"
                            >
                            </b-form-checkbox>
                        </b-col>
                    </b-row>
                </b-container>
            </template>
            <!-- image -->
            <div class="image">
                <b-img :alt="picture.meta.alt" :class="picture.publish ? '' : 'img-not_publish'"
                       :src="getImageSrc(picture.id, picture.image.src_p)"
                       :style="getImageSize"
                       center
                       draggable="false"
                       @dblclick="pictureEdit()"
                ></b-img>
            </div>
            <!-- footer: actions -->
            <template v-if="footer_visible" v-slot:footer>
                <div class="d-flex justify-content-center">
                    <!-- edit -->
                    <b-button class="ml-0" size="sm" variant="primary" @click="pictureEdit()">
                        <b-icon-pencil
                            v-b-tooltip.hover
                            :title="$t('form.edit')"
                            scale="1.2"
                        ></b-icon-pencil>
                    </b-button>
                    <!-- delete -->
                    <b-button class="ml-5" size="sm" variant="danger" @click="pictureRemove()">
                        <b-icon-Trash
                            v-b-tooltip.hover
                            :title="$t('form.delete')"
                            scale="1.2"
                            variant="light"
                        ></b-icon-Trash>
                    </b-button>
                </div>
            </template>
        </b-card>
        <!-- modal form -->
        <b-modal
            :id="'modalFormPictureData_' + picture.key"
            :cancel-title="$t('form.cancel')"
            :ok-title="$t('form.confirm')"
            :title="getName"
            footerBgVariant="light"
            headerBgVariant="light"
            ok-variant="warning"
            scrollable
            size="xl"
            @cancel=""
            @hide=""
            @ok="pictureCommit"
        >
            <form-picture-deprecated
                :data="formData"
                :form="form"
                :modalBus="modal.bus"
            ></form-picture-deprecated>
        </b-modal>
    </div>
</template>

<script>
import Vue from 'vue';
import FormPictureDeprecated from "@/components/general/elements/pictures/pictures_manager_deprecated/FormPictureDeprecated";
import utilsGeneral from "@/utils/utilsGeneral";

export default {
    name: "PictureDeprecated",
    components: {FormPictureDeprecated},
    props: {
        width: {
            type: Number,
            default: 220
        },
        height: {
            type: Number,
            default: 250
        },
        header_visible: {
            type: Boolean,
            default: true
        },
        header_height: {
            type: Number,
            default: 50
        },
        footer_visible: {
            type: Boolean,
            default: true
        },
        footer_height: {
            type: Number,
            default: 50
        },
        image_padding_x: {
            type: Number,
            default: 10
        },
        image_padding_y: {
            type: Number,
            default: 10
        },
        maxNameLength: {
            type: Number,
            default: 15
        },
        // <--  -->
        picture: {},
        record: {},
        pathMain: null,
        busPictureManager: {},
    },
    data() {
        return {
            form: {
                valid: null,
                picture: {},
                record: {
                    publish: true,
                }
            },
            formData: {},
            modal: {
                // titleDefault: this.$tc('currency', 1),
                // title: '',
                bus: new Vue(),
            },
            // hashOpen: '',   // TODO brisi
        }
    },
    mounted() {
    },
    methods: {
        getImageSrc(id, src) {
            if (id === 0) {
                return src;
            }
            return process.env.VUE_APP_API_IMAGES_PND + '/' + src;
        },
        // <-- picture -->
        parentUpdate() {
            this.$emit('eventPictureUpdate', this.picture);
        },
        pictureRemove() {
            this.$emit('eventPictureRemove', this.picture.key);
        },
        // <-- modal -->
        hideModal() {
            // <-- hide the modal manually -->
            this.$nextTick(() => {
                this.$bvModal.hide('modalFormPictureData_' + this.picture.key);
            });
        },
        // <-- modalForm -->
        pictureEdit() {
            this.$emit('showOverlay', true);
            this.axios.get(this.pathMain + `/${this.picture.id}`, this.__config)
                .then((response) => {
                    // <-- form -->
                    this.$set(this.form, 'valid', false);
                    this.$set(this.form, 'picture', this.picture.id > 0 ? response.data.picture : utilsGeneral.clone(this.picture));
                    // <-- data -->
                    this.$set(this, 'formData', response.data.data);
                    // <--  -->
                    this.$bvModal.show('modalFormPictureData_' + this.picture.key);
                    this.$emit('showOverlay', false);
                })
                .catch((error) => {
                    // console.log("error:", error);
                })
                .finally(function () {
                    // always executed
                });
        },
        pictureCommit(bvModalEvent) {
            // <-- check if form data is changed -->
            // let hashClose = this.CryptoJS.MD5(JSON.stringify(this.picture)).toString();
            // if (this.hashOpen === hashClose) {
            // return;
            // }
            // <-- prevent default to close modal -->
            bvModalEvent.preventDefault();
            // <-- check form validity from modal -->
            this.modal.bus.$emit('submit');
            if (!this.form.valid) {
                return;
            }
            // <-- if form valid commit record -->
            this.$set(this.picture, 'meta', this.form.picture.meta);
            this.$set(this.picture, 'locale', this.form.picture.locale);
            this.parentUpdate();
            this.hideModal();
        },
        picturePublish() {
            let data = {
                key: this.picture.key,
                publish: this.picture.publish,
            }
            this.$emit('eventPicturePublish', data);
        },
    },
    computed: {
        getSize() {
            let width = this.width;
            let height = this.height;
            // <--  -->
            return {'width': width + 'px', 'height': height + 'px'};
        },
        getImageSize() {
            let width = this.width - this.image_padding_x;
            let height = this.height - this.image_padding_y;
            height -= this.header_visible ? this.header_height : 0;
            height -= this.footer_visible ? this.footer_height + 5 : 0;
            // <--  -->
            return {'max-width': width + 'px', 'max-height': height + 'px'};
        },
        getName() {
            let name = this.picture.meta.name;
            // <-- if locale name exists -->
            let value = this.picture;

            if (value.locale != null && utilsGeneral.keyExists(value.locale, this.$i18n.locale)) {
                name = value.locale[this.$i18n.locale].name == null || !value.locale[this.$i18n.locale].name.length > 0 ? name : value.locale[this.$i18n.locale].name;
            }
            // <-- optimize name length -->
            // Ne dela ok, zaradi različnih širin črk
            let maxNameLength = this.maxNameLength;
            let indexLength = this.picture.index.toString().length;
            maxNameLength -= indexLength - 1;

            if (name.length > maxNameLength) {
                name = name.substr(0, maxNameLength) + ' ...';
            }
            // <--  -->
            return utilsGeneral.capitalize(name);
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

.border-new {
    border: 2px solid;
}

.card-header {
    padding: 0.75rem 0 0.75rem 0;
}

.card-footer {
    /*padding: 0.75rem 0rem 0.75rem 0.5rem;*/
    /*padding-y: 0.75rem;*/
    /*padding-x: 0;*/
}

.text-not_publish {
    color: silver;
}

.img-not_publish {
    opacity: 0.3;
}

.image {
    height: 100%;
    padding-top: 3px;
}
</style>
